const faqList = [
  {
    question: "Why Maple BJJ?",
    answer: "At Maple BJJ, you'll be training under one of the top-ranked competitive black belts in Canada. Our head instructor is a Canadian National Champion, a multi-time IBJJF Open Champion, and a multi-time Ontario Open Champion. Whether you’re a complete beginner or looking to compete, you’ll receive the highest level of instruction in a welcoming and supportive environment."
  },


  {
    question: "Do you take kids? What age groups do you teach?",
    answer: "At the moment, we are enrolling students aged 13 and up (Teens & Adults). While we don't currently offer a kids program, we're happy to recommend other local academies that specialize in training younger children. Feel free to reach out for recommendations, and thank you for considering us!"
  },
    {
      question: "What is Brazilian Jiu-Jitsu (BJJ)?",
      answer: "Brazilian Jiu-Jitsu (BJJ) is a grappling-based martial art that focuses on ground fighting and submissions. It emphasizes leverage, technique, and strategy over brute strength, making it highly effective for people of all sizes."
    },

    {
      question: "Do you offer Muay Thai & Mixed Martial Arts?",
      answer: "Right now, we’re dedicated to developing and perfecting our Brazilian Jiu-Jitsu program. However, we do have plans to introduce Muay Thai and Mixed Martial Arts (MMA) in the future, so stay tuned for updates!"
    },
    {
      question: "Do I need experience to start?",
      answer: "No, you don’t need any prior experience to start training at Maple Jiu-Jitsu. Our classes are designed for all skill levels, from beginners to advanced practitioners."
    },
    {
      question: "Is it safe to train at Maple BJJ?",
      answer: "Yes, we prioritize safety and promote a safe, supportive environment. Our instructors are experienced in ensuring that all training is conducted with care, minimizing the risk of injury."
    },
    {
      question: "What should I wear to class?",
      answer: "For No-Gi classes, you should wear shorts and a rashguard. For Gi classes, you will need a Gi, which we also offer for purchase at the academy."
    },
    {
      question: "Do you offer a free trial?",
      answer: "Yes, we offer one free week to those who have never been here before and want to try it out.",
      link: { text: "Click here to get started", url: "https://waiver.smartwaiver.com/v/maple" }
    },
 
  ];

  export default faqList;
  