import '../Stylesheets/Legend.css'

export default function Legend(){
    return(
        <div className='legend-container'>




            
        </div>
    )

}