import comp from '../Media/comp.webp'
import gi from '../Media/gi.webp'
import nogi from '../Media/nogi.jpg'
import kids from '../Media/kids.jpg'
import mma from '../Media/mma.jpg'
const Programs = [
    {image: gi,  name:'Gi Jiu-Jitsu', description: 'Training in the gi emphasizes grips, leverage, and technical precision, offering students a deep understanding of BJJ fundamentals. Whether you are a beginner or advanced practitioner, our Gi classes will help you sharpen your skills, improve fitness, and develop a strong sense of discipline. This program is perfect for anyone looking to fully immerse themselves in the traditional art of Brazilian Jiu-Jitsu while learning valuable self-defense techniques.'},
    {image: nogi,name:'No-Gi Jiu-Jitsu', description: 'Our No-Gi Brazilian Jiu-Jitsu program focuses on grappling techniques without the traditional gi, emphasizing speed, agility, and positional control. This program is perfect for those who want to improve their grappling skills in a faster-paced environment. Whether you are a beginner or an experienced practitioner, our No-Gi classes will push you to refine your skills and become a versatile grappler.' },
    {image: comp,name:'Competition Training', description: 'Whether you are drilling specific techniques with a partner, sharpening your game, or simply rolling, the focus is on self-directed practice. It is an open, flexible training environment where you can come in, work on what you need, and push yourself to the next level. Ideal for competitors looking to refine their skills or anyone wanting more time on the mats, this program encourages independence and hands-on learning with like-minded training partners.' },
    
]

const BackupPrograms = [
    
    {image: kids,name:'Kids Classes', description: 'Our Kids program is designed to build confidence, discipline, and coordination in a fun and supportive environment. We focus on teaching the fundamentals of BJJ while instilling important life values like respect, perseverance, and teamwork. Our classes are tailored for young learners, helping them develop both mentally and physically. Whether your child is new to martial arts or looking to build on existing skills, our Kids program is the perfect place for them to grow.'},
    {image: mma,name:'Mixed Martial Arts', description: 'Our Mixed Martial Arts (MMA) program combines the best techniques from various disciplines, including striking, grappling, and ground control. Our MMA program offers comprehensive training for all levels. With a focus on integrating techniques from Brazilian Jiu-Jitsu, Muay Thai, wrestling, and boxing, you will develop the skills needed to be a well-rounded martial artist. This program is ideal for those seeking to challenge themselves both mentally and physically.'}
]

export default Programs;