const schedule = {
    Monday: [
      { name: "BJJ - NoGi", start: 7.5, end: 8.5 },
      { name: "BJJ - NoGi", start: 12, end: 13 },
      { name: "BJJ - NoGi", start: 18.5, end: 19.5 }
    ],
    Tuesday: [
      { name: "BJJ - Gi", start: 7.5, end: 8.5 },
      { name: "BJJ - Gi", start: 12, end: 13 },
      { name: "BJJ - Gi", start: 18.5, end: 19.5 }
    ],
    Wednesday: [
      { name: "BJJ - NoGi", start: 7.5, end: 8.5 },
      { name: "BJJ - NoGi", start: 12, end: 13 },
      { name: "BJJ - NoGi", start: 18.5, end: 19.5 }
    ],
    Thursday: [
      { name: "BJJ - Gi", start: 7.5, end: 8.5 },
      { name: "BJJ - Gi", start: 12, end: 13 },
      { name: "BJJ - Gi", start: 18.5, end: 19.5 }
    ],
    Friday: [
      { name: "BJJ - NoGi", start: 7.5, end: 8.5 },
      { name: "BJJ - NoGi", start: 12, end: 13 },
      { name: "BJJ - NoGi", start: 18.5, end: 19.5 }
    ],
    Saturday: [
      { name: "BJJ - Gi", start: 12, end: 13},
    ]
    ,
    Sunday: [
      { name: "Open Mat", start: 12, end: 13},
    ]
  };
export default schedule  